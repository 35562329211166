import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSDK } from "./sdk";
import { Barcode, SparkScan, SparkScanListener, SparkScanSession } from "@scandit/web-datacapture-barcode";

export const ScannerComponent: React.FC<{ updateSearchValue: (value: string) => void; showVideo: boolean }> = ({ updateSearchValue, showVideo }) => {
  const host = useRef<HTMLDivElement | null>(null);
  const { loaded, sdk } = useSDK();
  const [barcode, setBarcode] = useState<Barcode | null>(null);
  const navigate = useNavigate();

  const onScan = useMemo<SparkScanListener>(
    () => ({
      didScan: async (_: SparkScan, session: SparkScanSession, frameData: any) => {
        if (session.newlyRecognizedBarcode && session.newlyRecognizedBarcode.data) {
          await sdk.enableScanning(false);
          await sdk.enableCamera(false);
          setBarcode(session.newlyRecognizedBarcode);
          updateSearchValue(session.newlyRecognizedBarcode.data);
        }
      },
      didUpdateSession: (_: SparkScan, session: SparkScanSession) => {
        // Handle session updates if necessary
      },
    }),
    [setBarcode, sdk, updateSearchValue]
  );

  useEffect(() => {
    if (loaded && host.current && showVideo) {
      sdk.connectToElement(host.current);
      sdk.enableCamera(true);
      sdk.enableScanning(true);
      sdk.addSparkScanListener(onScan);
    }
    return () => {
      if (loaded) {
        sdk.removeSparkScanListener(onScan);
        sdk.detachFromElement();
      }
    };
  }, [loaded, sdk, showVideo, onScan]);

  return <div ref={host} style={{ width: '100%', height: '100%' }} />;
};
