import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './search.module.css';
import { NavigationType, useLocation, useNavigate } from "react-router-dom";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { History, Update, Blocker } from "history";
import * as SDCCore from "@scandit/web-datacapture-core";
import { ScannerComponent } from './scanit.component';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useSDK } from './sdk';

interface CategoryProps {
    updateSearchValue: (value: string) => void;
    showError?: boolean;
}

export const Search: React.FC<CategoryProps> = ({ updateSearchValue, showError = false }) => {
    const { t } = useTranslation(['check', 'ui']);
    const { loaded, sdk } = useSDK();
    const [searchValue, setSearchValue] = useState<string>('');
    const [showScanner, setShowScanner] = useState(false);

    const { height, width } = useWindowDimensions();
    const isMobile = width < 600;
    const isTablet = (width >= 600 && width < 1024);
    const isDesktop = width >= 1024;

    const toggleScanner = () => {
        setShowScanner(!showScanner);
    };

    const handleScanResult = (scanResult: any) => {
        setSearchValue(scanResult);
        updateSearchValue(scanResult);
        backHandler();
    };

    const searchBtnClickHandler = () => {
        updateSearchValue(searchValue);
    };

    const handleKeyDown = (key: string) => {
        if (key === 'Enter') {
            searchBtnClickHandler();
        }
    };

    const [scanerAvailable, setScanerAvailable] = useState<boolean>(false);

    const [showVideo, setShowVideo] = useState<boolean>(false);

    const [flashlight, setFlashlight] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>(null);

    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log("Version: 1.0.5")
    // }, []);

    // useEffect(() => {
    //     // @ts-ignore
    //     if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //         // Проверка поддержки BarcodeDetector
    //         if ('BarcodeDetector' in window) {
    //             // Оба условия выполнились, устанавливаем scan в true
    //             setScanerAvailable(true)
    //         } else {
    //             // setScanerAvailable(false)
    //             // alert('Ваш браузер не поддерживает BarcodeDetector.')
    //             console.error('Ваш браузер не підтримує BarcodeDetector.');
    //         }
    //     } else {
    //         // setScanerAvailable(false)
    //         // alert('Ваш браузер не поддерживает getUserMedia для камеры.')
    //         console.error('Ваш браузер не підтримує getUserMedia для камери.');
    //     }
    // }, []);

    const [stream, setStream] = useState<any>(null);
    const unblockRef = useRef<Blocker | null>(null);
    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
            try {
                videoRef.current.play();
                let step = 0;
                const timerIdClear = setInterval(() => {
                    step++;
                    if (step > 4) {
                        clearTimeout(timerIdClear);
                    }
                    try {
                        stream.play();
                        // console.log('stream play success')
                        clearTimeout(timerIdClear);
                    } catch (err) {
                        console.error('Custom stream play', err);
                    }
                }, 250);
            } catch (err) {
                console.error('Custom play', err);
            }

            barcodeDetect();
            const unblockR = navigatorR.block(({ action, location, retry }) => {
                backHandler();
            });
            unblockRef.current = unblockR;
        }
    }, [stream]);
    const navigatorR = useContext(UNSAFE_NavigationContext).navigator as History;

    const barcodeDetect = async () => {
        try {
            // @ts-ignore
            const barcodeDetector = new BarcodeDetector();
            await new Promise((resolve) => setTimeout(resolve, 500));
            while (showVideo && videoRef.current && stream) {
                const barcodes = await barcodeDetector.detect(videoRef.current);
                // console.log('barcodes', barcodes)
                if (barcodes.length < 1) {
                    await new Promise((resolve) => setTimeout(resolve, 250));
                    continue;
                }

                const barcode = barcodes[0];

                if (!barcode) {
                    throw new Error('No barcode');
                }

                const { rawValue } = barcode;
                if (!rawValue) {
                    throw new Error('No rawValue');
                }

                setSearchValue(rawValue);
                updateSearchValue(rawValue);
                backHandler();
                break;
            }
        } catch (err) {
            // @ts-ignore
            if (err.message) {
                // @ts-ignore
                // alert(err.message);
                console.log(err.message)
            } else {
                // alert(err);
                console.log(err)
            }
            setScanerAvailable(false);
        }
    };

    const backHandler = () => {
        setShowVideo(false);
        setFlashlight(false);
        const header = document.querySelector('header');
        const footer = document.querySelector('footer');
        if (header && footer) {
            header.style.display = 'block';
            footer.style.display = 'block';
        }
        sdk.enableScanning(false);
        sdk.enableCamera(false);
        // if (!stream) {
        //     return;
        // }
        // const track = stream.getTracks()[0];
        // track.stop();
        // @ts-ignore
        unblockRef.current && unblockRef.current();
    };


    const videoStart = async () => {
        try {
            // console.log('videoStart', showVideo)
            // const constraints = {
            //     video: { width: { ideal: 9840 }, facingMode: { exact: 'environment' }, resizeMode: { ideal: "crop-and-scale" } }, audio: false
            // };

            // const streamMedia = await navigator.mediaDevices.getUserMedia(constraints)
            // const tracks = streamMedia.getTracks();
            // if (!tracks || typeof tracks !== 'object' || tracks.length < 1) {
            //     throw new Error('No tracks');
            // }

            // const track = tracks[0];
            // // @ts-ignore
            // await track.applyConstraints({ zoom: { ideal: 2 } })

            const header = document.querySelector('header');
            const footer = document.querySelector('footer');
            if (header && footer) {
                header.style.display = 'none';
                footer.style.display = 'none';
            }
            setShowVideo(true)
            // setStream(streamMedia)
            // navigate('/check', { replace: true });
            navigatorR.push('/check?scan', { replace: true })
            const unblockR = navigatorR.block(({ action, location, retry }) => {
                backHandler();
                // navigatorR.push('/check', { replace: true });

            });
            unblockRef.current = unblockR;
        } catch (err: any) {
            if (err['message']) {
                alert(err.message)
            } else {
                alert(err)
            }
            setScanerAvailable(false)
        }

    }

    // useEffect(() => {
    //     if (flashlight && stream) {
    //         const track = stream.getTracks()[0];
    //         const capabilities = track.getCapabilities();
    //         if (!capabilities || !capabilities.torch) {
    //             return;
    //         }
    //         track.applyConstraints({
    //             advanced: [{ torch: true }]
    //         });
    //     } else if (stream) {
    //         const track = stream.getTracks()[0];
    //         const capabilities = track.getCapabilities();
    //         if (!capabilities || !capabilities.torch) {
    //             return;
    //         }
    //         track.applyConstraints({
    //             advanced: [{ torch: false }]
    //         });
    //     }

    // }, [flashlight]);

    useEffect(() => {
        if (flashlight) {
            sdk.setDesiredTorchState(SDCCore.TorchState.On)
        } else {
            sdk.setDesiredTorchState(SDCCore.TorchState.Off)
        }
    }, [flashlight])

    const handleScannerAvailability = (value: boolean) => {
        setScanerAvailable(value);
    };

    const toggleVideo = () => {
        setShowVideo(!showVideo);
    }


    return (
        <>
            <section className={classes.section}>
                {searchValue.length > 0 &&
                    <div className={classes.searchClear} onClick={() => setSearchValue('')}></div>
                }

                <input
                    type="text"
                    className={[classes.input, scanerAvailable ? classes.inputWithScanner : ''].join(" ")}
                    onKeyDown={(e) => handleKeyDown(e.key)}
                    placeholder={t('inputGoodId')}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <button className={classes.button} onClick={searchBtnClickHandler}>
                    {isMobile
                        ? (
                            <svg style={{ height: '-webkit-fill-available' }} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></svg>
                        )
                        : (
                            t('search')
                        )
                    }

                </button>
                {loaded &&
                    <div onClick={videoStart} className={classes.barcode}></div>
                }
            </section>
            {showError && <div className={classes.showError}>{t('invalidInput')}</div>}
            {showVideo &&
                <div className={classes.videoWrapper}>
                    <div className={classes.video}>
                        <div className={classes.videoMenuWrapper}>
                            <div className={classes.videoMenu}>
                                <div className={classes.videoMenuClose} onClick={backHandler}></div>
                                <div className={classes.videoMenuTitle}>{t('scaner')}</div>
                                <div className={[classes.videoMenuFlashlight, flashlight ? classes.videoMenuFlashlightActive : undefined].join(" ")} onClick={() => setFlashlight(!flashlight)}></div>
                            </div>
                        </div>
                        <div className={classes.videoStream}>
                            {/* <video ref={videoRef} className={classes.video} autoPlay playsInline muted></video> */}
                            <ScannerComponent updateSearchValue={handleScanResult} showVideo={showVideo} />
                            {/* <div className={classes.focus}></div> */}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}            