import { lazy, Suspense } from 'react'
import MainPage from "../../pages/main/main";
import DiscountPage from "../../pages/discount/discount";
import { FAQPage } from "../../pages/faq";
import { LoginPage } from "../../pages/auth/login";
import { RegistrationPage } from "../../pages/auth/registration";
import { ResetPage } from "../../pages/auth/reset";
import { PolicyPage } from "../../pages/policy/policy";
import { OfferPage } from "../../pages/offer/offer";
import { SubscriptionPage } from "../../pages/cabinet/subscription";
import { NotificationSettingsPage } from "../../pages/cabinet/notifications";
import { CabinetPage } from "../../pages/cabinet/cabinet";
import { AdminPage } from "../../pages/admin/admin";
import { ProtectRoute } from "./protectRoute";
import { ProtectAdminRoute } from "./protectAdminRoute";
import { MessagesPage } from "../../pages/cabinet/messages";
import { SettingsPage } from "../../pages/cabinet/settings";
// import NotificationsPage from "../../pages/admin/notifications";
// import GoodsSubscriptionsPage from "../../pages/admin/goods-subscriptions";
// import ParserPage from "../../pages/admin/parser";
// import UsersPage from "../../pages/admin/users";
import { NewsPage } from "../../pages/news/news";
import { RedirectPage } from "../../pages/redirect";
import { RemovePage } from "../../pages/remove/remove";
import { ContactsPage } from "../../pages/contacts/contacts";
import { BrowserPage } from "../../pages/browser/browser";
import { CheckPage } from "../../pages/check/check";
import { Navigate } from "react-router-dom";
const NotificationsPage = lazy(() => import("../../pages/admin/notifications"));
const GoodsSubscriptionsPage = lazy(() => import("../../pages/admin/goods-subscriptions"));
const ParserPage = lazy(() => import("../../pages/admin/parser"));
const UsersPage = lazy(() => import("../../pages/admin/users"));
const FuncPage = lazy(() => import("../../pages/admin/func"));

export const Routenames = {
  MAIN: "/",
  DISCOUNT: "/discount",
  NEWS: "/news",
  FAQ: "/faq",
  LOGIN: "/login",
  REGISTRATION: "/registration",
  POLICY: "/policy",
  OFFER: "/offer",
  RESET: "/reset",
  REMOVE: "/remove",
  SUBSCRIPTION: "/cabinet/sub",
  NOTIFICATION_SETTINGS: "/cabinet/notification-settings",
  MESSAGES: "/cabinet/messages",
  SETTINGS: "/cabinet/settings",
  ANY: "*",
  ADMIN_USERS: "/admin/users",
  ADMIN_FUNC: "/admin/func",
  ADMIN_NOTIFICATIONS: "/admin/notifications",
  ADMIN_GOODS_SUBSCRIPTIONS: "/admin/goods-subscriptions",
  ADMIN_SETTINGS: "/admin/settings",
  ADMIN_PARSER: "/admin/parser",
  REDIRECT: "/redirect/:id",
  REDIRECT_ORIGIN: "/redirect/:id/:origin",
  CONTACTS: "/contacts",
  BROWSER: "/install",
  CHECK: "/check",
};

export const routes = [
  {
    path: Routenames.MAIN,
    element: <MainPage />,
  },
  {
    path: Routenames.DISCOUNT,
    element: <DiscountPage />,
  },
  {
    path: Routenames.FAQ,
    element: <FAQPage />,
  },
  {
    path: Routenames.LOGIN,
    element: <LoginPage />,
  },
  {
    path: Routenames.REGISTRATION,
    element: <RegistrationPage />,
  },
  {
    path: Routenames.RESET,
    element: <ResetPage />,
  },
  {
    path: Routenames.POLICY,
    element: <PolicyPage />,
  },
  {
    path: Routenames.OFFER,
    element: <OfferPage />,
  },
  {
    path: Routenames.CONTACTS,
    element: <ContactsPage />,
  },
  {
    path: Routenames.NEWS,
    element: <NewsPage />,
  },
  {
    path: Routenames.REMOVE,
    element: <RemovePage />,
  },
  {
    path: Routenames.BROWSER,
    element: <BrowserPage />,
  },
  {
    path: Routenames.CHECK,
    element: <CheckPage />,
  },
  {
    path: Routenames.SUBSCRIPTION,
    element: (
      <ProtectRoute>
        <CabinetPage>
          <SubscriptionPage />
        </CabinetPage>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.NOTIFICATION_SETTINGS,
    element: (
      <ProtectRoute>
        <CabinetPage>
          <NotificationSettingsPage />
        </CabinetPage>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.MESSAGES,
    element: (
      <ProtectRoute>
        <CabinetPage>
          <MessagesPage />
        </CabinetPage>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.SETTINGS,
    element: (
      <ProtectRoute>
        <CabinetPage>
          <SettingsPage />
        </CabinetPage>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.ADMIN_USERS,
    element: (
      <ProtectRoute>
        <ProtectAdminRoute>
          <AdminPage>
            <UsersPage />
          </AdminPage>
        </ProtectAdminRoute>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.ADMIN_FUNC,
    element: (
      <ProtectRoute>
        <ProtectAdminRoute>
          <AdminPage>
            <FuncPage />
          </AdminPage>
        </ProtectAdminRoute>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.ADMIN_NOTIFICATIONS,
    element: (
      <ProtectRoute>
        <ProtectAdminRoute>
          <AdminPage>
            <NotificationsPage />
          </AdminPage>
        </ProtectAdminRoute>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.ADMIN_GOODS_SUBSCRIPTIONS,
    element: (
      <ProtectRoute>
        <ProtectAdminRoute>
          <AdminPage>
            <GoodsSubscriptionsPage />
          </AdminPage>
        </ProtectAdminRoute>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.ADMIN_PARSER,
    element: (
      <ProtectRoute>
        <ProtectAdminRoute>
          <AdminPage>
            <ParserPage />
          </AdminPage>
        </ProtectAdminRoute>
      </ProtectRoute>
    ),
  },
  {
    path: Routenames.REDIRECT,
    element: <RedirectPage />,
  },
  {
    path: Routenames.REDIRECT_ORIGIN,
    element: <RedirectPage />,
  },
];
