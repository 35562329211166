import { 
  Symbology, 
  SparkScan, 
  SparkScanListener, 
  SparkScanSettings 
} from "@scandit/web-datacapture-barcode";
import { 
  TorchState, 
  DataCaptureContext, 
  DataCaptureView, 
  CameraSwitchControl, 
  Camera, 
  FrameSourceState, 
  configure,
  AimerViewfinder, 
  Color
} from "@scandit/web-datacapture-core";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { barcodeCaptureLoader } from "@scandit/web-datacapture-barcode"; // Используем существующий loader

export interface SDK {
  initialize: () => Promise<void>;
  cleanup: () => Promise<void>;
  connectToElement: (element: HTMLElement) => void;
  detachFromElement: () => void;
  enableCamera: (enabled: boolean) => Promise<void>;
  enableScanning: (enabled: boolean) => Promise<void>;
  enableSymbology: (symbology: Symbology, enabled: boolean) => Promise<void>;
  addSparkScanListener: (callback: SparkScanListener) => void;
  removeSparkScanListener: (callback: SparkScanListener) => void;
  getEnabledSymbologies: () => Symbology[] | undefined;
  setDesiredTorchState: (enabled: TorchState) => Promise<void>;
}

export interface SDKWithLoadingStatus {
  loading: boolean;
  loaded: boolean;
  sdk: SDK;
}

export function createSDKFacade(): SDK {
  let context: DataCaptureContext | undefined;
  let view: DataCaptureView | undefined;
  let settings: SparkScanSettings | undefined;
  let sparkScan: SparkScan | undefined;
  let host: HTMLElement | undefined;
  let cameraSwitchControl: CameraSwitchControl | undefined;
  let camera: Camera | undefined;
  let aimerViewfinder: AimerViewfinder | undefined;

  function createHostElementIfNeeded(): HTMLElement {
    if (!host) {
      host = document.createElement("div");
      host.style.display = "none";
      host.style.width = "100%";
      host.style.height = "100%";
      document.body.append(host);
    }
    return host;
  }

  return {
    async initialize() {
      // Конфигурация SDK с использованием существующего loader
      await configure({
        libraryLocation: 'https://cdn.jsdelivr.net/npm/@scandit/web-datacapture-barcode@7.0/sdc-lib',
        licenseKey: "ApI1kA0HKzWICPhn3ObfrI4cjgXiFkKnhgEmUvAt0D3DdcQQiECDiORylvTWdrazq2J96kE0NkFMcyFbc0HEN7RXjFKgX9rQxBS0P9gHao+LT6Oz9QMrsJoJRC/OboodKS4/F+ZVa02eUT53X0GX9jJeEnh8EerqZimrl6oDjNLvHCzBnxtmsMZidAUaErqK5yaQQCkUKcfTQhRzVhqvsjdENR76EmrrvCSr93oMCnnDHjcyOz8O3stcNrCOGbxwDBIMwf05CzoEBhxF9SoT/fwvfWa9BRvlMTxl9lUUE2f3Aw9+0CBG7PYgZ9XGCiCM2Wy9T5cf0C5MOLr2MAIgQrlDua0oEtmC5FjFojQHJEITRmLyQnAyUU8Y+jkUUCtQNmweZKtTQT9le+G9QCyWrEAyVsVwAOtIeX+UiJ42PhANeI/vJSaQ2VpDgZVrV1Vc4V/z0Exu7Ap7R0MpzCy4hhRzdpWgf4OG815ZctwMvM7qfBqykG98B9oP3YC/LG+cpVK4Ipx5VnWBUIwVHDiocHhMjyroTBAeH3BfeCdOEpKwNqBRFk//wl5gB8peBNmV3WQGegVVMHwwc+7xOlyXAQRjMvN6dJgJ+UOuUY5QvwDIdDSq+SNrsgUUc4A7Au+4g3K3fo0qrLjIOEiEgw0agwt+d4hycSa0O3C7QyUQodh6UvGmL33ivbhxWOzxKQjjBHHt80AZRe4oWGhCDkCZfqpcKboWSi7GyGzRNy8eTsrWSPn70hm+SVw0TP9q1mgRk7KGfAYv2I70SgEPUNO1Z+J5XbmptYEof70yPxloF69UdhZsOuWMNgR5GyD0vs89LnCAx+tDWPKISPdmrJAYEUXQv99kIGj7IKfm8vk6kVJGip4feaM9MF5+D8oGcnNw9xJTFPExlgxU5Aq4ZQRWktOmnYTTW6/+S3A0U8CPntwAR4bSCQ8EEw7ARK7dAL2rnWbAiHJMe8ZAQHm7O1FcAEQKxnjzJkeb9V5cAM7wVR1RgpGacty30e0Fmca/Dgjs3j21FdXtxWRz++vhiY+7wXXb+RyvHbCrbcuHHmrc9r2pg+12BTarQx2kZqEG5AgRz/TNwm8+bW5lpXW0zlb0rul6FlEMwX6zKzE8hWOfLIlUgFJ5ByjHPgQ9mc12Tcd1XLXcN+u9yzpZHL9gcCbliqGXJ0ywDIOFNWy9xeCjXyWtmS1gf6YLh/zeaHGaNCalcoNttU7RaDQe4YVTThRiuVIy4OeVa0WAUfE3bkg/tiqlJVHES30uHDwR/+UDT6Gx2YF9IQYmPzpq4s49/aOEUCCxQ0C1qsmmYmMJvRXCqXYlP+FbhBBxtcmSsadVl5XpVc5ZuxVVBuWgyblLQRCfuBzoVCyxJDfgHJnd8vBGeoXv2lsG9QzW8e3ZtL5JkYg3GSXHG0foxa7bGcmDYaYCBzhrMVekQU3nuF5Kmw==",
        moduleLoaders: [barcodeCaptureLoader()], // Используем существующий loader
      });

      // Создание контекста захвата данных
      context = await DataCaptureContext.create();

      // Настройка SparkScan
      settings = new SparkScanSettings();
      settings.enableSymbologies([
        Symbology.EAN13UPCA,
        Symbology.EAN8,
        Symbology.UPCE,
        Symbology.QR,
        Symbology.DataMatrix,
        Symbology.Code39,
        Symbology.Code128,
        Symbology.InterleavedTwoOfFive,
      ]);

      // Создание экземпляра SparkScan
      sparkScan = SparkScan.forSettings(settings);
      await sparkScan.setEnabled(false);

      // Добавление SparkScan в контекст
      context.addMode(sparkScan);

      // Создание и настройка DataCaptureView
      view = await DataCaptureView.forContext(context);
      view.connectToElement(createHostElementIfNeeded());
      view.showProgressBar();
      view.setProgressBarMessage('Loading ...');

      // Добавление переключателя камеры
      cameraSwitchControl = new CameraSwitchControl();
      view.addControl(cameraSwitchControl);

      aimerViewfinder = new AimerViewfinder();
      aimerViewfinder.frameColor = Color.fromRGBA(255, 0, 0, 255); // Пример настройки цвета рамки
      aimerViewfinder.dotColor = Color.fromRGBA(0, 255, 0, 255);   // Пример настройки цвета точки
      view.addOverlay(aimerViewfinder);

      // Настройка камеры
      camera = Camera.default;
      try {
        // // Попытка использовать рекомендуемые настройки камеры для SparkScan
        // const cameraSettings = SparkScan.recommendedCameraSettings; // Если существует
        // await camera.applySettings(cameraSettings);
      } catch (error) {
        console.warn("recommendedCameraSettings отсутствуют, используются стандартные настройки камеры.");
        // Если рекомендуемые настройки отсутствуют, можно применить стандартные настройки или оставить без изменений
      }
      await context.setFrameSource(camera);
      view.hideProgressBar();
    },
    async cleanup() {
      if (context?.frameSource) {
        await context.frameSource.switchToDesiredState(FrameSourceState.Off);
      }
      if (sparkScan) {
        await context?.removeMode(sparkScan);
      }
      await context?.dispose();

      if (cameraSwitchControl) {
        view?.removeControl(cameraSwitchControl);
        cameraSwitchControl = undefined;
      }

      view?.detachFromElement();
      sparkScan = undefined;
      context = undefined;
      view = undefined;
      settings = undefined;
      camera = undefined;
      host?.remove();
      host = undefined;
    },
    connectToElement(element: HTMLElement) {
      host = createHostElementIfNeeded();
      host.style.display = "block";
      element.append(host);
    },
    
    detachFromElement() {
      if (host) {
        host.style.display = "none";
        document.body.append(host);
      }
    },
    async enableCamera(enabled: boolean) {
      if (context?.frameSource) {
        await context.frameSource.switchToDesiredState(enabled ? FrameSourceState.On : FrameSourceState.Off);
      }
    },
    async enableScanning(enabled: boolean) {
      await sparkScan?.setEnabled(enabled);
    },
    async setDesiredTorchState(enabled: TorchState) {
      await camera?.setDesiredTorchState(enabled);
    },
    async enableSymbology(symbology: Symbology, enabled: boolean) {
      settings!.enableSymbologies([symbology]);
      await sparkScan?.applySettings(settings!);
    },
    addSparkScanListener(listener: SparkScanListener) {
      sparkScan?.addListener(listener);
    },
    removeSparkScanListener(listener: SparkScanListener) {
      sparkScan?.removeListener(listener);
    },
    getEnabledSymbologies() {
      return settings!.enabledSymbologies;
    },
  };
}

export const SDKContext = createContext({
  loaded: false,
  loading: false,
  sdk: null,
} as unknown as SDKWithLoadingStatus);

export interface SDKProviderProps {
  children: ReactNode;
}

export default function SDKProvider({ children }: SDKProviderProps): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const sdk = useMemo(() => createSDKFacade(), []);

  const providerValue = useMemo(() => ({ loading, loaded, sdk }), [loading, loaded, sdk]);

  useEffect(() => {
    async function start(): Promise<void> {
      setLoading(true);
      await sdk.initialize();
      setLoading(false);
      setLoaded(true);
    }
    void start();
    return () => {
      void sdk.cleanup();
    };
  }, [sdk]);

  return <SDKContext.Provider value={providerValue}>{children}</SDKContext.Provider>;
}

export function useSDK(): SDKWithLoadingStatus {
  const value = useContext(SDKContext);
  if (value.sdk === null) {
    throw new Error("Sdk facade is null. Возможно, вы забыли обернуть компонент в SDKProvider?");
  }
  return value;
}
